<template>
  <div class="educational-teacher-view">
    <v-row>
      <v-col>
        <h2 class="main-title mb-0">
          {{ $t("Educational History") }}
        </h2>
      </v-col>
      <v-col class="text-right">
        <v-btn
          class="green-btn"
          @click="saveResult"
          v-if="dataArr && dataArr.length > 0 && edit == true"
          :loading="loading"
          :disabled="loading"
          >{{ $t("Save") }}</v-btn
        >
        <v-btn
          class="main-btn"
          @click="edit = true"
          v-if="
            dataArr && dataArr.length > 0 && edit == false && isActive == false
          "
          >{{ $t("Edit") }}</v-btn
        >
        <v-btn
          class="main-btn"
          @click="cancelEdit()"
          v-if="dataArr && dataArr.length > 0 && edit == true"
          >{{ $t("Cancel") }}
        </v-btn>
      </v-col>
    </v-row>

    <v-form ref="selectForm" v-model="selectvalid">
      <v-row>
        <v-col>
          <label for="Quarters">{{ $t("Quarters") }}</label>
          <v-select
            v-model="selectedQuarter"
            :items="quarters"
            item-text="name"
            item-value="id"
            label="Quarters"
            :rules="selectValidation"
            @click="showTable = false"
            @change="getSubjects"
            required
            solo
            dense
          ></v-select>
        </v-col>
        <v-col>
          <label for="Grades">{{ $t("Grades") }}</label
          ><v-select
            v-model="selectedGrade"
            :items="grades"
            :rules="selectValidation"
            item-text="name"
            item-value="id"
            label="Grades"
            @change="getClassesAndSubjects"
            solo
            dense
          ></v-select
        ></v-col>
        <v-col>
          <label for="Classes">{{ $t("Classes") }}</label>
          <v-select
            v-model="selectedClasses"
            :rules="selectValidation"
            :items="classes"
            item-text="name"
            item-value="id"
            label="Classes"
            @change="getQualifiers"
            solo
            dense
          ></v-select>
        </v-col>
        <v-col>
          <label for="Subjects">{{ $t("Subjects") }}</label>
          <v-select
            v-model="selectedSubject"
            :rules="selectValidation"
            :items="subjects"
            item-text="name"
            item-value="id"
            label="Subjects"
            @change="getQualifiers"
            solo
            dense
          ></v-select>
        </v-col>
        <v-col>
          <label for="Qualifiers">{{ $t("Qualifiers") }}</label>
          <v-select
            v-model="selectedQualifiers"
            @change="showTable = false"
            :rules="selectValidation"
            :items="qualifiers"
            item-text="title"
            item-value="id"
            label="Qualifiers"
            solo
            dense
          ></v-select>
        </v-col>
        <v-col>
          <v-btn
            class="green-btn mt-10"
            @click="getResults()"
            :loading="loading"
            :disabled="loading"
            :title="$t('Filter')"
          >
            <v-icon>search</v-icon>
            {{ $t("Filter") }}</v-btn
          >
        </v-col>
      </v-row>
    </v-form>

    <v-row>
      <v-col cols="12" class="text-right">
        <v-btn
          small
          class="icon-btn"
          icon
          @click.prevent="addColumn()"
          :title="$t('Add New Column')"
          v-if="dataArr && dataArr.length > 0 && edit == true"
        >
          <v-icon color="#ffffff">mdi-plus</v-icon>
        </v-btn>
      </v-col>
      <v-col v-if="dataArr && dataArr.length > 0 && showTable == true">
        <v-form ref="form" v-model="valid">
          <v-simple-table class="elevation-1 level1">
            <template v-slot:default>
              <thead>
                <tr>
                  <th style="padding-top: 16px !important; text-align: center">
                    {{ $t("student name") }}
                  </th>
                  <th
                    class=""
                    style="padding-top: 16px !important; text-align: center"
                    v-for="(item, index) in headersArr"
                    :key="index"
                  >
                    {{ item.title }} {{ index + 1 }} ({{ qualifiersMark }})
                  </th>

                  <th style="padding-top: 16px !important; text-align: center">
                    {{ $t("Total") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="headersArr.length > 1 && edit == true">
                  <td></td>
                  <td v-for="(value, index) in headersArr" :key="index">
                    <v-btn
                      small
                      icon
                      class="icon-btn"
                      @click.prevent="removeColumn(index)"
                      :title="$t('Remove the Column')"
                    >
                      <v-icon color="#ffffff">mdi-minus</v-icon>
                    </v-btn>
                  </td>
                </tr>
                <tr v-for="(item, index) in dataArr" :key="index">
                  <td>{{ item.student_name }}</td>
                  <td v-for="(cell, cellIndex) in item.values" :key="cellIndex">
                    <v-text-field
                      v-if="edit == true"
                      v-model="item.values[cellIndex]"
                      :rules="qualifierValidation"
                      width="300px"
                      solo
                    >
                    </v-text-field>
                    <p v-else>{{ item.values[cellIndex] }}</p>
                  </td>

                  <td>
                    {{ totalList[index] }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-form>
      </v-col>
      <v-col v-else>
        <v-alert type="info" v-if="noData == false">
          {{ $t("Click filter button to filter") }}
        </v-alert>
        <v-alert type="info" v-else>
          {{ $t("No data available") }}
        </v-alert>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "AdminTeacherView",
  data() {
    return {
      loading: false,
      isActive: false,
      noData: false,
      showTable: false,
      valid: false,
      selectvalid: false,
      firstLoad: false,
      selectValidation: [(v) => !!v || this.$i18n.t("This field is required")],
      qualifierValidation: [
        (v) => !!v || this.$i18n.t("Mark is required"),
        (v) => {
          if (v) {
            return (
              (!isNaN(v) && Number(v) >= 0 && /^\d+(\.\d{1,2})?$/.test(v)) ||
              this.$i18n.t("Please enter valid number")
            );
          } else return true;
        },
        (v) => {
          const totalMark = this.qualifiersMark;
          if (v && v > totalMark) {
            return this.$i18n.t("Mark shouldn't exceed total mark");
          } else {
            return true;
          }
        },
      ],
      edit: true,
      qualifiersGrade: 20,

      headersArr: [],
      dataArr: [
        // {
        //   student_name: "Mahmoud",
        //   values: ["10", "10", "10"],
        // },
        // {
        //   student_name: "Mustafa",
        //   values: ["20", "20", "20"],
        // },
        // {
        //   student_name: "Ahmed",
        //   values: ["30", "30", "30"],
        // },
      ],
      lastIndex: 1,
      quarters: [],
      grades: [],
      classes: [],
      subjects: [],
      qualifiers: [],
      selectedQuarter: "",
      selectedGrade: "",
      selectedClasses: "",
      selectedSubject: "",
      selectedQualifiers: "",
      qualifiersMark: "",
    };
  },
  computed: {
    totalList() {
      let Total = [];
      let rowTotal = 0;

      this.dataArr.forEach((item, index) => {
        item.values.forEach((value) => {
          rowTotal += Number(value);
        });

        let countQualifiers = this.dataArr[index].values.length;
        Total.push(Number(rowTotal / countQualifiers, 1));
        rowTotal = 0;
      });

      return Total;
    },
  },
  methods: {
    getClassesAndSubjects() {
      this.getClasses();
      this.getSubjects();
    },
    cancelEdit() {
      this.edit = false;
      this.getResults();
    },
    updateQualifiersHeaders() {
      this.headersArr.forEach((head, index) => {
        // this.headersArr[index] = head + (index + 1);
        this.$set(this.headersArr, index, { title: 33, total_mark: 33 });
      });
      this.lastIndex = this.headersArr.length + 1;
    },
    addColumn() {
      if (this.headersArr.length < 10) {
        let qualifiersTitle = this.headersArr[0].title;
        let qualifiersMark = this.qualifiersMark;

        this.headersArr.push({
          title: qualifiersTitle,
          total_mark: qualifiersMark,
        });
        this.dataArr.forEach((item) => {
          item.values.push("");
        });
        this.lastIndex++;
      }
    },
    removeColumn(index) {
      if (this.headersArr.length && this.headersArr.length > 1) {
        this.headersArr.splice(index, 1);
        this.dataArr.forEach((item) => {
          item.values.splice(index, 1);
        });
      }
    },

    getQuarters() {
      this.noData = false;
      this.showTable = false;
      this.selectedQuarter = "";
      axios
        .get(this.getApiUrl + "/educational-history/filter/quarters", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.quarters = response.data.data;
          if (this.quarters && this.quarters.length > 0) {
            this.selectedQuarter = this.quarters[0].id;
          }
        });
    },
    getGrades() {
      this.noData = false;
      this.showTable = false;
      this.selectedGrade = "";
      axios
        .get(this.getApiUrl + "/educational-history/filter/grades/0", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.grades = response.data.data;
          if (this.grades && this.grades.length > 0) {
            this.selectedGrade = this.grades[0].id;
            this.getClasses();

            this.getSubjects();
          }
        });
    },
    getClasses() {
      this.noData = false;
      this.showTable = false;
      this.selectedSubject = "";
      this.selectedClasses = "";
      axios
        .get(
          this.getApiUrl +
            "/educational-history/filter/classes/" +
            this.selectedGrade,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.classes = response.data.data;
          // this.subjects = response.data.data.subjects;

          if (this.classes && this.classes.length > 0) {
            this.selectedClasses = this.classes[0].id;
          }
        });
    },

    getSubjects() {
      this.selectedSubject = "";
      if (this.selectedGrade) {
        axios
          .get(
            this.getApiUrl +
              "/educational-history/filter/subjects/" +
              this.selectedQuarter +
              "?grade_id=" +
              this.selectedGrade,
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            this.subjects = response.data.data;

            if (this.subjects && this.subjects.length > 0) {
              this.selectedSubject = this.subjects[0].id;
              this.getQualifiers();
            }
          });
      }
    },

    getQualifiers() {
      this.noData = false;
      this.showTable = false;
      this.selectedQualifiers = "";
      this.qualifiers = [];
      axios
        .get(
          this.getApiUrl +
            "/educational-history/filter/qualifiers/" +
            this.selectedSubject,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.qualifiers = response.data.data;
          if (this.qualifiers && this.qualifiers.length > 0) {
            this.selectedQualifiers = this.qualifiers[0].id;
            if (this.firstLoad == false) {
              this.getResults();
            }

            this.firstLoad = true;
          } else {
            this.dataArr = [];
            this.headersArr = [];
          }
        });
    },

    getResults() {
      setTimeout(() => {
        if (this.selectvalid) {
          this.loading = true;
          axios
            .get(
              this.getApiUrl +
                "/educational-history/filter/results?quarter_id=" +
                this.selectedQuarter +
                "&class_id=" +
                this.selectedClasses +
                "&subject_id=" +
                this.selectedSubject +
                "&subjects_qualifier_id=" +
                this.selectedQualifiers +
                "&academic_grade_id=" +
                this.selectedGrade,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              this.loading = false;
              this.showTable = true;
              this.dataArr = response.data.data.students;
              this.headersArr = response.data.data.headers;
              this.isActive =
                response.data.data.is_active == undefined
                  ? false
                  : response.data.data.is_active;
              this.qualifiersMark = this.headersArr[0].total_mark;
              // this.updateQualifiersHeaders();
              if (this.dataArr && this.dataArr.length == 0) {
                this.noData = true;
              }
              this.edit =
                this.dataArr && this.dataArr[0].values[0] == "" ? true : false;

              // if (this.data.data.update == true) {
              //   this.edit = false;
              // } else {
              //   this.edit = true;
              // }
            });
        } else {
          this.$refs.selectForm.validate();
          this.noData = true;
        }
      }, 200);
    },

    saveResult() {
      if (this.valid) {
        this.loading = true;
        axios
          .post(
            this.getApiUrl +
              "/educational-history/store?quarter_id=" +
              this.selectedQuarter +
              "&class_id=" +
              this.selectedClasses +
              "&subject_id=" +
              this.selectedSubject +
              "&subjects_qualifier_id=" +
              this.selectedQualifiers +
              "&academic_grade_id=" +
              this.selectedGrade,
            { students: this.dataArr },
            {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((response) => {
            this.loading = false;
            if (response.data.status.error == false) {
              this.edit = false;
            }
          });
      } else {
        this.$refs.form.validate();
      }
    },
  },
  mounted() {
    this.getQuarters();
    this.getGrades();
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_forms.scss";

.main-title {
  color: gray;
  font-weight: 700;
}

.icon-btn {
  background-color: $main-color;
}

.green-btn {
  background-color: #4caf50 !important;
  margin: 0rem 1rem;
}
</style>
